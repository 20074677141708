<template>
  <div class="swiper-container" id="banner">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, i) in groupList" :key="i" @click="openUrl(item)">
        <img :src="item.imgUrl" class="img" />
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'mySwiper',
  props: {
    homeBanner: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  },
  computed: {
    groupList() {
      return this.homeBanner
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let s = new Swiper('#banner', {
        paginationClickable: true,
        speed: 1000,
        loop: true,
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        autoplay: { delay: 2000 },
        pagination: {
          el: '.swiper-pagination'
        }
      })
    },
    click(item) {
      // console.log(item)
      // TODO 跳转链接
      if (item.path) {
        this.$router.push({ path: item.path })
      }
    },
    openUrl(item) {
      // console.log(item)
      window.location.href = item.jumpPath
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.swiper-container {
  width: 100%;
  border-radius: 20px;
  .swiper-slide {
    background: #0031bd;
    .img {
      box-sizing: border-box;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }
  /deep/.swiper-pagination-bullet-active {
    background: #fff !important;
  }
}
@media screen and (min-width: 1600px) {
  .swiper-container {
    height: 518px;
  }
}
@media screen and (max-width: 1680px) and (min-width: 1440px) {
  .swiper-container {
    height: 440px;
  }
}
@media screen and (max-width: 1440px) {
  .swiper-container {
    height: 438px;
  }
}
</style>
